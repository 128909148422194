import React, { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify'; // required on every page
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import '../styling/colors.module.css';
import '../styling/fonts.module.css';
import * as pageStyles from './menu_item_form.module.css';
import MenuToolAmplifyAuthContainer from '../components/MenuToolAmplifyAuthContainer';
import Header from '../components/MenuToolHeader';
import Button from '../components/form/Button';
import Input from '../components/form/Input';
import DateInput from '../components/form/DateInput';
import UnpackedIngredients from '../components/form/UnpackedIngredientsList';
import CaretDown from '../components/form/CaretDown';
import MenuItemCategoryList from '../components/form/MenuItemCategoryList';
import HelpTextTooltip from '../components/HelpTextTooltip';

const cloneDeep = require('lodash.clonedeep');

const _ = require('lodash');

/**
 TODO:
 * GENERAL TODOs:
 *
 * - where 'setResults' is passed, instead throw an Error. that way external functions can stay "pure"
 * - add second graphQL call so we fetch both "saved" and "published" data
 * - share code between "handleSubmission" and "handlePublish". is there any difference other than the mutation name?
 *
 * FUTURE REFACTORINGS:
 * - further separate field data & presentation. ideally we'd have a complex object of the different form fields, their name & id, etc
 *   and we'd be able to iterate over them all. see categories, date picker sections for inspiration
 */

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
  },
});

const makeAsyncCall = async (query, method) => {
  const { accessToken } = await Auth.currentSession();
  const jwt = accessToken.getJwtToken();
  const result = await fetch(process.env.GATSBY_AWS_APPSYNC_API_URL, {
    body: query,
    headers: {
      authorization: jwt,
      'content-type': 'application/json',
    },
    method,
  });
  const resultJson = await result.json();

  /**
   * TODO: handle `results.errors` existence and throw error if so
   */
  return resultJson;
};

function clean(object) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      clean(v);
    }
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
}

const baseItemState = {
  allergens: undefined,
  bay: undefined,
  calories: undefined,
  caloriesWithDressing: undefined,
  categories: [],
  daysToExpiry: undefined,
  description: undefined,
  displayName: undefined,
  fridgeStatus: undefined,
  fridgeTags: [],
  ingredients: undefined,
  jarSize: undefined,
  lastSaleDate: undefined,
  launchDate: undefined,
  maxPerSlot: undefined,
  packagingType: undefined,
  packingSlipSort: undefined,
  perishable: undefined,
  price: undefined,
  publicStatus: undefined,
  sku: undefined,
  status: undefined,
  substitutionSku: undefined,
  sundryType: undefined,
  sunsetTimestamp: undefined,
  taxCode: undefined,
  unpackedIngredients: [
    {
      contents: [{ description: undefined, name: undefined }],
      itemName: undefined,
    },
  ],
  upc: undefined,
  zone: undefined,
};

const allMenuItemFieldsPartial = `
      allergens
      bay
      calories
      caloriesWithDressing
      categories
      daysToExpiry
      description
      displayName
      foodInfo{
        ingredients
        unpackedIngredients {
          contents {
            description
            name
          }
          itemName
        }
      }
      fridgeStatus
      jarSize
      lastSaleDate
      launchDate
      maxPerSlot
      packagingType
      packingSlipSort
      perishable
      publicStatus
      sku
      status
      substitutionSku
      sundryType
      sunsetTimestamp
      taxCode
      upc
      vPrice
      zone
`;

const allMenuItemsInputTypes = `
  $allergens: String,
  $bay: String,
  $calories: Int,
  $caloriesWithDressing: Int,
  $categories: [String],
  $daysToExpiry: Int,
  $description: String,
  $displayName: String,
  $fridgeStatus: String,
  $ingredients: String,
  $jarSize: String,
  $lastSaleDate: AWSDate,
  $launchDate: AWSDate,
  $maxPerSlot: Int,
  $packagingType: String,
  $packingSlipSort: Int,
  $perishable: Boolean,
  $price: Float,
  $publicStatus: String,
  $sku: String!,
  $status: String,
  $substitutionSku: String,
  $sundryType: SundryType,
  $sunsetTimestamp: AWSDate,
  $taxCode: String,
  $unpackedIngredients: [MenuItemIngredientItemsInput],
  $upc: String,
  $zone: String
`;

const allMenuItemsInputPartial = `
  allergens: $allergens,
  bay: $bay,
  calories: $calories,
  caloriesWithDressing: $caloriesWithDressing,
  categories: $categories,
  daysToExpiry: $daysToExpiry,
  description: $description,
  displayName: $displayName,
  foodInfo: {
    ingredients: $ingredients,
    unpackedIngredients: $unpackedIngredients,
  },
  fridgeStatus: $fridgeStatus,
  jarSize: $jarSize,
  lastSaleDate: $lastSaleDate,
  launchDate: $launchDate,
  maxPerSlot: $maxPerSlot,
  packagingType: $packagingType,
  packingSlipSort: $packingSlipSort,
  perishable: $perishable,
  publicStatus: $publicStatus,
  sku: $sku,
  status: $status,
  substitutionSku: $substitutionSku,
  sundryType: $sundryType,
  sunsetTimestamp: $sunsetTimestamp,
  taxCode: $taxCode,
  upc: $upc,
  vPrice: $price,
  zone: $zone
`;

const resetItemState = setStagingItem => {
  setStagingItem(_.cloneDeep(baseItemState));
};

const setItemState = (itemStateCurrent, setItemFunction) => {
  const {
    allergens,
    bay,
    calories,
    caloriesWithDressing,
    categories,
    daysToExpiry,
    description,
    displayName,
    foodInfo: { ingredients },
    fridgeStatus,
    jarSize,
    lastSaleDate,
    launchDate,
    maxPerSlot,
    packagingType,
    packingSlipSort,
    perishable,
    publicStatus,
    sku,
    status,
    substitutionSku,
    sundryType,
    sunsetTimestamp,
    taxCode,
    upc,
    vPrice: price,
    zone,
  } = itemStateCurrent;
  let {
    foodInfo: { unpackedIngredients },
  } = itemStateCurrent;
  if (!unpackedIngredients)
    unpackedIngredients = _.cloneDeep(baseItemState.unpackedIngredients);

  setItemFunction({
    allergens,
    bay,
    calories,
    caloriesWithDressing,
    categories,
    daysToExpiry,
    description,
    displayName,
    fridgeStatus,
    ingredients,
    jarSize,
    lastSaleDate,
    launchDate,
    maxPerSlot,
    packagingType,
    packingSlipSort,
    perishable,
    price,
    publicStatus,
    sku,
    status,
    substitutionSku,
    sundryType,
    sunsetTimestamp,
    taxCode,
    unpackedIngredients,
    upc,
    zone,
  });
};

// translate packagingType to corresponding jarSize
const packagingTypeToJarSizeDict = {
  '4oz_Jar': 'Small_Snack',
  '12oz_Jar': 'Medium_Dish',
  '16oz_Jar': 'Medium_Dish',
  '20oz_Jar': 'Large_Salad',
  '32oz_Jar': 'Large_Salad',
  // eslint-disable-next-line prettier/prettier
  'Beverage_Thick_Can': 'Can_12OZ',
  // eslint-disable-next-line prettier/prettier
  'Beverage_Thin_Tall_Can': 'Can_12OZ',
  // eslint-disable-next-line prettier/prettier
  'CPG_Product': 'Small_Snack',
  // eslint-disable-next-line prettier/prettier
  'Deep_Box': 'Medium_Dish',
  // eslint-disable-next-line prettier/prettier
  'Retail_Sandwich_Box': 'Medium_Dish',
  // eslint-disable-next-line prettier/prettier
  'Shallow_Box': 'Large_Salad',
};

/**
 * TODO: add `menuItemSaved` call here and pass to setItemState as well
 */
const fetchSkuInfo = async (
  sku,
  setResults,
  setStagingItem,
  setPublishedItem,
  setHasPublishedInfo,
) => {
  const query = JSON.stringify({
    query: `query { menuItemStaging(sku: "${sku}") {
      ${allMenuItemFieldsPartial}
    }
    menuItemPublished(sku: "${sku}") {
      ${allMenuItemFieldsPartial}
    }
  }
      `,
  });
  let results;
  try {
    results = await makeAsyncCall(query, 'POST');
    if ('errors' in results) {
      setResults(results?.errors[0]?.message);
    }
  } catch (error) {
    console.log('errors retrieving data');
    setResults(error);
  }
  if (results?.data?.menuItemPublished[0]) {
    setHasPublishedInfo(true);
    setItemState(results?.data?.menuItemPublished[0], setPublishedItem);
  }

  if (results?.data?.menuItemStaging[0]) {
    setItemState(results?.data?.menuItemStaging[0], setStagingItem);
  }
  if (
    results?.data?.menuItemPublished[0] &&
    !results?.data.menuItemStaging[0]
  ) {
    setItemState(results?.data?.menuItemPublished[0], setStagingItem);
  }
  return null;
};

const uploadToS3 = async (file, url, setResults) => {
  try {
    const urlResults = await fetch(url, {
      body: file,
      method: 'PUT',
    });
    console.log('Url fetched');
    if ('errors' in urlResults) {
      setResults(urlResults?.errors[0]?.message);
    }
  } catch (error) {
    setResults(error);
  }
};

const downloadFromS3 = async (url, setResults) => {
  try {
    const urlResults = await fetch(url, {
      method: 'PUT',
    });
    console.log('Url fetched');
    if ('errors' in urlResults) {
      setResults(urlResults?.errors[0]?.message);
    }
  } catch (error) {
    setResults(error);
  }
};

const uploadPhoto = async (file, pictureName, setResults, itemSku) => {
  try {
    const query = JSON.stringify({
      query: `mutation CreateMenuToolPreSignedUrl($fileName: String!, $path: String!) {
                createMenuToolPreSignedUrl(input: {fileName: $fileName, path: $path, type: upload})
              }
            `,
      variables: {
        fileName: pictureName,
        path: itemSku,
      },
    });
    const urlInfo = await makeAsyncCall(query, 'POST');
    if ('errors' in urlInfo) {
      setResults(urlInfo?.errors[0]?.message);
    }
    uploadToS3(file, urlInfo.data.createMenuToolPreSignedUrl, setResults);
  } catch (error) {
    console.log('errors getting presigned url');
    setResults(error);
  }
};

const downloadPhoto = async (
  pictureName,
  setResults,
  itemSku,
  setPictureCallback,
) => {
  try {
    const query = JSON.stringify({
      query: `mutation CreateMenuToolPreSignedUrl($fileName: String!, $path: String!) {
                createMenuToolPreSignedUrl(input: {fileName: $fileName, path: $path, type: download})
              }
            `,
      variables: {
        fileName: pictureName,
        path: itemSku,
      },
    });
    const urlInfo = await makeAsyncCall(query, 'POST');
    if ('errors' in urlInfo) {
      setResults(urlInfo?.errors[0]?.message);
    }
    setPictureCallback(urlInfo);
    downloadFromS3(urlInfo.data.createMenuToolPreSignedUrl, setResults);
  } catch (error) {
    console.log('errors getting presigned url');
    setResults(error);
  }
};

const handlePhotoUpload = (
  setResults,
  itemSku,
  selectedIconFile,
  selectedNutritionFile,
  selectedTopDownFile,
) => {
  if (selectedIconFile) {
    uploadPhoto(selectedIconFile, 'InStock.png', setResults, itemSku);
  }
  if (selectedNutritionFile) {
    uploadPhoto(selectedNutritionFile, 'Nutritional.png', setResults, itemSku);
  }
  if (selectedTopDownFile) {
    uploadPhoto(selectedTopDownFile, 'Detailed.png', setResults, itemSku);
  }
};

async function getListOfPackagingTypes() {
  const query = JSON.stringify({
    query: `
      query {
        packagingTypes
      }
    `,
  });
  const resultJson = await makeAsyncCall(query, 'POST');
  if (resultJson.errors) {
    // loop through to display all
    throw resultJson.errors[0];
  }
  return resultJson.data.packagingTypes;
}

const MenuItemForm = ({ location }) => {
  const [authState, setAuthState] = useState();
  const [results, setResults] = useState();
  const [user, setUser] = useState();
  const [selectedIconFile, setSelectedIconFile] = useState();
  const [uploadedIconFileInStock, setUploadedIconFileInStock] = useState();
  const [uploadedIconFileDetailed, setUploadedIconFileDetailed] = useState();
  const [uploadedIconFileNutrition, setUploadedIconFileNutrition] = useState();
  const [selectedNutritionFile, setSelectedNutritionFile] = useState();
  const [selectedTopDownFile, setSelectedTopDownFile] = useState();
  const isUpdate = Boolean(new URLSearchParams(location.search).get('skuName'));
  const hiddenNutritionInput = React.useRef(null);
  const hiddenIconInput = React.useRef(null);
  const hiddenTopDownInput = React.useRef(null);

  const [stagingItem, setStagingItem] = useState(_.cloneDeep(baseItemState));
  const [publishedItem, setPublishedItem] = useState(
    _.cloneDeep(baseItemState),
  );
  const [hasPublishedInfo, setHasPublishedInfo] = useState();
  const [packagingTypes, setPackagingTypes] = useState([]);

  useEffect(() => {
    async function fetchPackagingTypes() {
      const types = await getListOfPackagingTypes();
      setPackagingTypes(types);
    }
    fetchPackagingTypes();
  }, []);

  const handleNutritionFileUploadClick = event => {
    hiddenNutritionInput?.current?.click();
  };
  const handleIconFileUploadClick = event => {
    hiddenIconInput?.current?.click();
  };
  const handleTopDownFileUploadClick = event => {
    hiddenTopDownInput?.current?.click();
  };

  const handleSubmission = async e => {
    e.preventDefault();
    if (!stagingItem.sku) {
      setResults('field SKU is required');
      return;
    }
    if (stagingItem.sku.toUpperCase() !== stagingItem.sku) {
      setResults('Sku must be capitalized');
      return;
    }

    /* eslint-disable no-restricted-syntax */
    for (const ingredient of stagingItem.unpackedIngredients) {
      if (!ingredient.itemName) {
        setResults(
          'Item name in Unpacked Ingredients section is a required field',
        );
        return;
      }
      if (ingredient.contents?.length === 0) {
        setResults(
          'At least one content entry is required for each unpacked ingredient',
        );
        return;
      }
      for (const content of ingredient.contents) {
        if (!content.name) {
          setResults(
            'Name is required for each content entry in unpacked ingredients',
          );
          return;
        }
      }
    }
    const stagingItemPure = clean(stagingItem);

    handlePhotoUpload(
      setResults,
      stagingItem.sku,
      selectedIconFile,
      selectedNutritionFile,
      selectedTopDownFile,
    );
    const mutationSaveMenuItem = JSON.stringify({
      query: `mutation
      (${allMenuItemsInputTypes})
      {
        saveMenuItemStaging(input:
          {
            ${allMenuItemsInputPartial}
          }) {
            ${allMenuItemFieldsPartial}
          }
        }
        `,
      variables: stagingItemPure,
    });

    try {
      const savedSku = await makeAsyncCall(mutationSaveMenuItem, 'POST');
      if ('errors' in savedSku) {
        setResults(savedSku?.errors[0]?.message);
      } else {
        document.location.href = '/';
      }
    } catch (error) {
      console.log('errors getting presigned url');
      setResults(error);
    }
  };

  const handlePublish = async e => {
    e.preventDefault();
    if (!stagingItem.sku) {
      setResults('field SKU is required');
      return;
    }
    if (stagingItem.sku.toUpperCase() !== stagingItem.sku) {
      setResults('Sku must be capitalized');
      return;
    }
    for (const ingredient of stagingItem.unpackedIngredients) {
      if (!ingredient.itemName) {
        setResults(
          'Item name in Unpacked Ingredients section is a required field',
        );
        return;
      }
      if (ingredient.contents?.length === 0) {
        setResults(
          'At least one content entry is required for each unpacked ingredient',
        );
        return;
      }
      for (const content of ingredient.contents) {
        if (!content.name) {
          setResults(
            'Name is required for each content entry in unpacked ingredients',
          );
          return;
        }
      }
    }
    const stagingItemPure = clean(stagingItem);
    handlePhotoUpload(
      setResults,
      stagingItemPure.sku,
      selectedIconFile,
      selectedNutritionFile,
      selectedTopDownFile,
    );

    const mutationPublishMenuItem = JSON.stringify({
      query: `mutation
      (${allMenuItemsInputTypes})
      {
        publishMenuItemStaging(input:
          {
            ${allMenuItemsInputPartial}
          })
      }
        `,
      variables: stagingItemPure,
    });

    try {
      const promotedSku = await makeAsyncCall(mutationPublishMenuItem, 'POST');
      if ('errors' in promotedSku) {
        setResults(promotedSku?.errors[0]?.message);
      } else {
        document.location.href = '/';
      }
    } catch (error) {
      console.log('errors getting presigned url');
      setResults(error);
    }
  };

  const handleDiscard = async e => {
    e.preventDefault();
    if (!stagingItem.sku) {
      setResults('field SKU is required');
      return;
    }
    if (stagingItem.sku.toUpperCase() !== stagingItem.sku) {
      setResults('Sku must be capitalized');
      return;
    }
    const mutationDiscardMenuItem = JSON.stringify({
      query: `mutation discardMenuItemStagingChanges($sku: String!){
          discardMenuItemStagingChanges(sku: $sku){
              sku
        }
      }`,
      variables: {
        sku: stagingItem.sku,
      },
    });

    try {
      const discardedSku = await makeAsyncCall(mutationDiscardMenuItem, 'POST');
      if ('errors' in discardedSku) {
        setResults(discardedSku?.errors[0]?.message);
      } else {
        document.location.href = '/';
      }
    } catch (error) {
      console.log('errors discarding item');
      setResults(error);
    }
  };

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const skuName = searchParams.get('skuName');
    if (skuName) {
      downloadPhoto(
        'Nutritional.png',
        setResults,
        skuName,
        setUploadedIconFileNutrition,
      );
      downloadPhoto(
        'InStock.png',
        setResults,
        skuName,
        setUploadedIconFileInStock,
      );
      downloadPhoto(
        'Detailed.png',
        setResults,
        skuName,
        setUploadedIconFileDetailed,
      );
      fetchSkuInfo(
        skuName,
        setResults,
        setStagingItem,
        setPublishedItem,
        setHasPublishedInfo,
      );
    } else {
      resetItemState(setStagingItem);
      resetItemState(setPublishedItem);
    }
  }, []);

  function updateItemField(fieldName, value) {
    if (fieldName === 'packagingType') {
      setStagingItem(prevState => ({
        ...prevState,
        jarSize: packagingTypeToJarSizeDict[value] || 'Medium_Dish',
        packagingType: value,
      }));
    } else {
      setStagingItem(prevState => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  }

  function updateUnpackedIngredientsItemName(itemNumber, value) {
    const current = stagingItem?.unpackedIngredients;
    current[itemNumber].itemName = value;
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  function updateUnpackedIngredientsContentsName(
    itemNumber,
    contentNumber,
    value,
  ) {
    const current = stagingItem.unpackedIngredients;
    current[itemNumber].contents[contentNumber].name = value;
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  function updateUnpackedIngredientsContentsDescription(
    itemNumber,
    contentNumber,
    value,
  ) {
    const current = stagingItem.unpackedIngredients;
    current[itemNumber].contents[contentNumber].description = value;
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  function removeUnpackedIngredientItem(itemNumber) {
    const current = stagingItem.unpackedIngredients;
    if (current.length > 1) {
      current.splice(itemNumber, 1);
    } else {
      current[itemNumber] = { description: null, name: null };
    }
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  function addNewItem() {
    const current = stagingItem.unpackedIngredients;
    current.push({
      contents: [{ description: null, name: null }],
      itemName: null,
    });
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  function addNewIngredient(itemNumber) {
    const current = stagingItem.unpackedIngredients;
    current[itemNumber].contents.push({ description: null, name: null });
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  function removeIngredient(itemNumber, ingredientNumber) {
    const current = stagingItem.unpackedIngredients;
    current[itemNumber].contents.splice(ingredientNumber, 1);
    if (current[itemNumber].contents.length === 0) {
      current[itemNumber].contents.push({ description: null, name: null });
    }
    setStagingItem(prevState => ({
      ...prevState,
      unpackedIngredients: current,
    }));
  }

  return authState === AuthState.SignedIn && user ? (
    <div className={pageStyles.container}>
      <Header user={user} />
      <div className={pageStyles.menuForm}>
        <div className={pageStyles.headerText}>
          {isUpdate ? <h1>Edit Menu Item </h1> : <h1>Create New Menu Item </h1>}
          <p className={pageStyles.subtitle}>
            Create menu items using the form below. A item must be published for
            internal use, then launched to the public.
          </p>
          <div className={pageStyles.explanationWrapper}>
            <p className={pageStyles.internalLaunchExplanation}>
              *Indicates fields required for item to be published for internal
              use
            </p>
            <p className={pageStyles.publicLaunchExplanation}>
              ^ Indicates required for only public menu launch
            </p>
          </div>
        </div>
        <hr className={pageStyles.pageBreak} />
        {results && (
          <div className={pageStyles.errorMessage}>
            <p>Error with saving the data of: </p>
            <p>{JSON.stringify(results)}</p>{' '}
          </div>
        )}

        <form onSubmit={handleSubmission} className={pageStyles.form}>
          <div>
            <h2 className={pageStyles.sectionHeader}>Product Information</h2>
            <div className={pageStyles.formGroup}>
              <Input
                type="text"
                name="displayName"
                currentValue={stagingItem.displayName}
                handleChange={val => updateItemField('displayName', val)}
                title="Item Name (Title)"
                publishedValue={publishedItem.displayName}
                internalUse
              />

              <Input
                type="text"
                name="sku"
                currentValue={stagingItem.sku}
                handleChange={val =>
                  updateItemField(
                    'sku',
                    val.toUpperCase().replace(/[^A-Z0-9_]/g, ''),
                  )
                }
                title="Backend Name (Sku)"
                publishedValue={publishedItem.sku}
                internalUse
              />
              <div className={pageStyles.selectFloat}>
                <select
                  name="status"
                  id="productionStatus"
                  value={stagingItem.status || ''}
                  onChange={e => updateItemField('status', e.target.value)}
                  className={pageStyles.selectField}
                >
                  <option defaultValue value="">
                    Select Production Status
                  </option>
                  <option value="LIVE">LIVE</option>
                  <option value="OFFLINE">OFFLINE</option>
                </select>
                <label
                  className={pageStyles.formFields}
                  htmlFor="productionStatus"
                >
                  Production Status*
                  <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                {publishedItem && hasPublishedInfo && publishedItem?.status ? (
                  <p className={pageStyles.publishedValue}>
                    Current value: <i>{publishedItem.status}</i>
                  </p>
                ) : null}
                <HelpTextTooltip id="productionStatusHelpText">
                  If LIVE, orders can be placed in Facility CMS.
                </HelpTextTooltip>
              </div>
            </div>
            <div className={pageStyles.formGroup}>
              <Input
                type="number"
                name="price"
                currentValue={stagingItem.price || 0}
                handleChange={val =>
                  updateItemField('price', parseFloat(val, 10))
                }
                title="Delivery Price"
                publishedValue={publishedItem.price}
                internalUse
              />
              <Input
                type="number"
                name="daysToExpiry"
                currentValue={stagingItem.daysToExpiry || 0}
                handleChange={val =>
                  updateItemField('daysToExpiry', parseInt(val, 10))
                }
                min="0"
                title="Shelf Life"
                publishedValue={publishedItem.daysToExpiry}
                internalUse
              />
              <div className={pageStyles.selectFloat}>
                <select
                  id="packagingType"
                  name="packagingType"
                  className={pageStyles.selectField}
                  value={stagingItem.packagingType}
                  onChange={e =>
                    updateItemField('packagingType', e.target.value)
                  }
                  required
                >
                  <option value="">Select a Packaging Type</option>
                  {packagingTypes.map(packagingType => (
                    <option key={packagingType} value={packagingType}>
                      {packagingType}
                    </option>
                  ))}
                </select>
                <label htmlFor="packagingType">
                  Packaging Type*
                  <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                {publishedItem && hasPublishedInfo ? (
                  <p className={pageStyles.publishedValue}>
                    Current value: <i>{publishedItem.packagingType}</i>
                  </p>
                ) : null}
              </div>
            </div>
            <div className={pageStyles.formGroup}>
              <Input
                type="text"
                name="upc"
                currentValue={stagingItem.upc || ''}
                handleChange={val => updateItemField('upc', val)}
                title="UPC"
                publishedValue={publishedItem.upc}
                internalUse
              />
              <div className={pageStyles.selectFloat}>
                <select
                  name="sundryType"
                  id="sundryType"
                  className={pageStyles.selectField}
                  value={stagingItem.sundryType}
                  onChange={e => updateItemField('sundryType', e.target.value)}
                  title="Sundry Type"
                >
                  <option value={null}>Select Sundry Type</option>
                  <option value="None">None</option>
                  <option value="Fork">Fork</option>
                  <option value="Spoon">Spoon</option>
                </select>
                <label className={pageStyles.formFields} htmlFor="sundryType">
                  Sundry Type*
                  <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                {publishedItem && hasPublishedInfo ? (
                  <p className={pageStyles.publishedValue}>
                    Current value: &nbsp;
                    <i>{publishedItem.sundryType || 'undefined'}</i>
                  </p>
                ) : null}
              </div>
              <div className={pageStyles.selectFloat}>
                <select
                  name="perishable"
                  id="perishable"
                  className={pageStyles.selectField}
                  value={stagingItem.perishable}
                  onChange={e => updateItemField('perishable', e.target.value)}
                  title="Perishable"
                >
                  <option value={null}>Select Perishable Status</option>
                  <option value>Perishable</option>
                  <option value={false}>Non-Perishable</option>
                </select>
                <label className={pageStyles.formFields} htmlFor="perishable">
                  Perishable*
                  <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                {publishedItem && hasPublishedInfo ? (
                  <p className={pageStyles.publishedValue}>
                    Current value: &nbsp;
                    <i>
                      {{ false: 'Non-Perishable', true: 'Perishable' }[
                        publishedItem.perishable
                      ] || 'undefined'}
                    </i>
                  </p>
                ) : null}
              </div>
            </div>

            <MenuItemCategoryList
              stagingItem={stagingItem}
              // eslint-disable-next-line react/jsx-no-bind
              updateItemField={updateItemField}
              setResults={setResults}
            />
            {publishedItem && hasPublishedInfo && publishedItem?.categories ? (
              <p className={pageStyles.publishedValue}>
                Current value: <i>{publishedItem?.categories}</i>
              </p>
            ) : null}
            <label className={pageStyles.formFields}>
              Ingredients (Subname)*
              <textarea
                type="textarea"
                name="ingredients"
                value={stagingItem.ingredients || ''}
                onChange={e => updateItemField('ingredients', e.target.value)}
              />
            </label>
            {publishedItem && hasPublishedInfo && publishedItem?.ingredients ? (
              <p className={pageStyles.publishedValue}>
                Current value: <i>{publishedItem.ingredients}</i>
              </p>
            ) : null}
          </div>
          <div>
            <h2 className={pageStyles.sectionHeader}>
              Product Nutrition &amp; Ingredients
            </h2>
            <div className={pageStyles.formGroup}>
              <Input
                type="text"
                name="description"
                currentValue={stagingItem.description}
                handleChange={val => updateItemField('description', val)}
                title="Calorie Text (Example: Bowl: 240 Cal / Granola: 100 Cal)"
                publishedValue={publishedItem.description}
                internalUse
              />
              <Input
                min="0"
                type="number"
                name="calories"
                currentValue={stagingItem.calories || 0}
                handleChange={val =>
                  updateItemField('calories', parseInt(val, 10))
                }
                title="Base Calories"
                publishedValue={publishedItem.calories}
                publicLaunch
              />
              <Input
                min="0"
                type="number"
                name="caloriesWithDressing"
                currentValue={stagingItem.caloriesWithDressing}
                handleChange={val =>
                  updateItemField('caloriesWithDressing', parseInt(val, 10))
                }
                title="Calories with Dressing"
                publishedValue={publishedItem.caloriesWithDressing}
                publicLaunch
              />
            </div>
            <Input
              type="text"
              name="allergens"
              currentValue={stagingItem.allergens}
              handleChange={val => updateItemField('allergens', val)}
              title="allergens"
              publishedValue={publishedItem.allergens}
              internalUse
            />
            <UnpackedIngredients
              unpackedIngredients={
                stagingItem.unpackedIngredients || [
                  {
                    contents: [{ description: null, name: null }],
                    itemName: null,
                  },
                ]
              }
              updateUnpackedIngredientsItemName={(...args) => {
                updateUnpackedIngredientsItemName(...args);
              }}
              updateUnpackedIngredientsContentsName={(...args) => {
                updateUnpackedIngredientsContentsName(...args);
              }}
              updateUnpackedIngredientsContentsDescription={(...args) => {
                updateUnpackedIngredientsContentsDescription(...args);
              }}
              removeUnpackedIngredientItem={(...args) => {
                removeUnpackedIngredientItem(...args);
              }}
              addNewIngredient={(...args) => {
                addNewIngredient(...args);
              }}
              removeIngredient={(...args) => {
                removeIngredient(...args);
              }}
              addNewItem={() => {
                addNewItem();
              }}
              publishedValue={publishedItem.unpackedIngredients || null}
            />
          </div>
          <div className={pageStyles.imageSection}>
            <h2 className={pageStyles.sectionHeader}>Product Images</h2>
            <div className={pageStyles.formGroup}>
              <div className={pageStyles.iconButtonWrapper}>
                <Input
                  type="text"
                  name="topDownImageDisplay"
                  publicLaunch
                  currentValue={
                    uploadedIconFileDetailed?.data
                      ?.createMenuToolPreSignedUrl || selectedTopDownFile?.name
                  }
                  title="Top Down"
                />
                <Button
                  isSecondary
                  type="button"
                  handleSubmit={handleTopDownFileUploadClick}
                  key="topDownButton"
                >
                  Choose File
                </Button>
                <input
                  type="file"
                  name="detailImage"
                  style={{ display: 'none' }}
                  ref={hiddenTopDownInput}
                  onChange={e =>
                    stagingItem.sku
                      ? uploadPhoto(
                          e.target.files[0],
                          'Detailed.png',
                          setResults,
                          stagingItem.sku,
                        )
                      : setSelectedTopDownFile(e.target.files[0])
                  }
                />
              </div>
              <div className={pageStyles.iconButtonWrapper}>
                <Input
                  type="text"
                  name="iconImageDisplay"
                  currentValue={
                    uploadedIconFileInStock?.data?.createMenuToolPreSignedUrl ||
                    selectedIconFile?.name
                  }
                  title="Block Out"
                  publicLaunch
                />
                <Button
                  isSecondary
                  type="button"
                  handleSubmit={handleIconFileUploadClick}
                  key="iconButton"
                >
                  Choose File
                </Button>
                <input
                  type="file"
                  name="iconImage"
                  style={{ display: 'none' }}
                  ref={hiddenIconInput}
                  onChange={e =>
                    stagingItem.sku
                      ? uploadPhoto(
                          e.target.files[0],
                          'InStock.png',
                          setResults,
                          stagingItem.sku,
                        )
                      : setSelectedIconFile(e.target.files[0])
                  }
                />
              </div>

              <div className={pageStyles.iconButtonWrapper}>
                <Input
                  type="text"
                  name="nutritionImageDisplay"
                  publicLaunch
                  currentValue={
                    uploadedIconFileNutrition?.data
                      ?.createMenuToolPreSignedUrl ||
                    selectedNutritionFile?.name
                  }
                  title="Nutrition Facts Panel"
                />
                <Button
                  isSecondary
                  type="button"
                  handleSubmit={handleNutritionFileUploadClick}
                  key="nutritionButton"
                >
                  Choose File
                </Button>

                <input
                  type="file"
                  name="nutritionImage"
                  style={{ display: 'none' }}
                  ref={hiddenNutritionInput}
                  onChange={e =>
                    stagingItem.sku
                      ? uploadPhoto(
                          e.target.files[0],
                          'Nutritional.png',
                          setResults,
                          stagingItem.sku,
                        )
                      : setSelectedNutritionFile(e.target.files[0])
                  }
                />
              </div>
            </div>
            {/**
             * TODO: investigate why presigned URLs are not working
             */}
            {(uploadedIconFileInStock ||
              uploadedIconFileDetailed ||
              uploadedIconFileNutrition) &&
            !(
              (uploadedIconFileInStock &&
                'errors' in uploadedIconFileInStock) ||
              (uploadedIconFileDetailed &&
                'errors' in uploadedIconFileDetailed) ||
              (uploadedIconFileNutrition &&
                'errors' in uploadedIconFileNutrition)
            ) ? (
              <div className={pageStyles.formGroup}>
                <div className={pageStyles.individualImageContainer}>
                  <div className={pageStyles.imageLabel}>Top Down Preview</div>
                  <img
                    className={pageStyles.imageStyle}
                    src={
                      uploadedIconFileDetailed?.data?.createMenuToolPreSignedUrl
                    }
                    alt="No Detailed Upload"
                  />
                </div>
                <div className={pageStyles.detailedImage}>
                  <div className={pageStyles.imageLabel}>Block Out Preview</div>
                  <img
                    className={pageStyles.imageStyle}
                    src={
                      uploadedIconFileInStock?.data?.createMenuToolPreSignedUrl
                    }
                    alt="No Icon Upload"
                  />
                </div>
                <div className={pageStyles.nutritionImage}>
                  <div className={pageStyles.imageLabel}>
                    Nutrition Facts Panel Preview
                  </div>
                  <img
                    className={pageStyles.imageStyle}
                    src={
                      uploadedIconFileNutrition?.data
                        ?.createMenuToolPreSignedUrl
                    }
                    alt="No Nutrition Upload"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <h2 className={pageStyles.sectionHeader}>Fridge Logistics</h2>
            <div className={pageStyles.formGroup}>
              <Input
                type="number"
                name="maxPerSlot"
                currentValue={stagingItem.maxPerSlot || 0}
                handleChange={val =>
                  updateItemField('maxPerSlot', parseInt(val, 10))
                }
                min="0"
                title="Max Per Slot"
                publishedValue={publishedItem.maxPerSlot}
                internalUse
              />
              <div className={pageStyles.selectFloat}>
                <select
                  name="fridgeStatus"
                  id="fridgeStatus"
                  className={pageStyles.selectField}
                  value={stagingItem.fridgeStatus || ''}
                  onChange={e =>
                    updateItemField('fridgeStatus', e.target.value)
                  }
                >
                  <option defaultValue value="">
                    Select Status
                  </option>
                  <option value="LIVE">LIVE</option>
                  <option value="OFFLINE">OFFLINE</option>
                  <option value="DEAD">DEAD</option>
                </select>
                <label className={pageStyles.formFields} htmlFor="fridgeStatus">
                  Fridge Status{' '}
                  <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                <HelpTextTooltip id="fridgeStatusHelpText">
                  If LIVE, the item will appear on all flex menu fridges
                  regardless of planogram.
                </HelpTextTooltip>
                {publishedItem &&
                hasPublishedInfo &&
                publishedItem?.fridgeStatus ? (
                  <p className={pageStyles.publishedValue}>
                    Current value: <i>{publishedItem.fridgeStatus}</i>
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div>
            <h2 className={pageStyles.sectionHeader}>Delivery Logistics</h2>
            <div className={pageStyles.formGroup}>
              <Input
                type="text"
                name="taxCode"
                currentValue={stagingItem.taxCode}
                handleChange={val => updateItemField('taxCode', val)}
                title="Tax Code"
                publishedValue={publishedItem.taxCode}
                publicLaunch
              />
              <Input
                type="text"
                name="substitutionSku"
                currentValue={stagingItem.substitutionSku}
                handleChange={val => updateItemField('substitutionSku', val)}
                title="Substitution Sku"
                publishedValue={publishedItem.substitutionSku}
                publicLaunch
              />
              <div className={pageStyles.selectFloat}>
                <select
                  name="publicStatus"
                  id="publicStatus"
                  className={pageStyles.selectField}
                  value={stagingItem.publicStatus || ''}
                  onChange={e =>
                    updateItemField('publicStatus', e.target.value)
                  }
                >
                  <option defaultValue value="">
                    Select Status
                  </option>
                  <option value="LIVE">LIVE</option>
                  <option value="OFFLINE">OFFLINE</option>
                  <option value="OFFLINE">DEAD</option>
                </select>
                <label className={pageStyles.formFields} htmlFor="publicStatus">
                  Public Status{' '}
                  <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                <HelpTextTooltip id="publicStatusHelpText">
                  If LIVE, the item and some of its details will appear on the
                  app and website menus.
                </HelpTextTooltip>
                {hasPublishedInfo && publishedItem?.publicStatus && (
                  <p className={pageStyles.publishedValue}>
                    Current value: <i>{publishedItem.publicStatus}</i>
                  </p>
                )}
              </div>
            </div>

            <div className={pageStyles.formGroup}>
              {publishedItem &&
              hasPublishedInfo &&
              publishedItem?.publicStatus ? (
                <p className={pageStyles.publishedValue}>
                  Current value: <i>{publishedItem.publicStatus}</i>
                </p>
              ) : null}

              {publishedItem &&
              hasPublishedInfo &&
              publishedItem?.fridgeStatus ? (
                <p className={pageStyles.publishedValue}>
                  Current value: <i>{publishedItem.fridgeStatus}</i>
                </p>
              ) : null}

              {[
                {
                  id: 'launchDate',
                  label: 'Web Launch Date',
                  type: 'date',
                },
                {
                  id: 'sunsetTimestamp',
                  label: 'Last Day for Delivery',
                  type: 'timestamp',
                },
                {
                  id: 'lastSaleDate',
                  label: 'Web Removal Date',
                  type: 'date',
                },
              ].map(({ id, label, type }) => [
                [
                  <div>
                    <DateInput
                      id={id}
                      key={id}
                      label={label}
                      value={
                        stagingItem[id]
                          ? new Date(
                              stagingItem[id]
                                .replace(/-/g, '/')
                                .replace(/T.+/, ''),
                            )
                          : undefined
                      }
                      onChange={val => {
                        if (!val) {
                          updateItemField(id, undefined);
                        } else {
                          updateItemField(id, val.toISOString().split('T')[0]);
                        }
                      }}
                    />
                    {[
                      publishedItem && hasPublishedInfo && publishedItem[id] ? (
                        <p className={pageStyles.publishedValue}>
                          Current value: <i>{publishedItem[id]}</i>
                        </p>
                      ) : null,
                    ]}
                  </div>,
                ],
              ])}
            </div>
          </div>
          <div>
            <h2 className={pageStyles.sectionHeader}>Warehouse Logistics</h2>
            <div
              className={`${pageStyles.formGroup} ${pageStyles.warehouseLogistics}`}
            >
              <div className={pageStyles.selectFloat}>
                <select
                  name="Bay"
                  id="bay"
                  className={pageStyles.selectField}
                  value={stagingItem.bay || ''}
                  onChange={e => updateItemField('bay', e.target.value)}
                >
                  <option defaultValue value="">
                    Select Bay
                  </option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                </select>
                <label className={pageStyles.formFields} htmlFor="bay">
                  Bay <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                {publishedItem && hasPublishedInfo && publishedItem?.bay ? (
                  <p className={pageStyles.publishedValue}>
                    Current value: <i>{publishedItem.bay}</i>
                  </p>
                ) : null}
              </div>

              <div className={pageStyles.selectFloat}>
                <select
                  name="Zone"
                  id="zone"
                  className={pageStyles.selectField}
                  value={stagingItem.zone || ''}
                  onChange={e => updateItemField('zone', e.target.value)}
                >
                  <option defaultValue value="">
                    Select Zone
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
                <label className={pageStyles.formFields} htmlFor="zone">
                  Zone <CaretDown classes={pageStyles.caretInputDropdown} />
                </label>
                <p className={pageStyles.publishedValue}>
                  Current value: <i>{publishedItem.zone}</i>
                </p>
              </div>

              <div className={pageStyles.formGroup}>
                <div className={pageStyles.selectFloat}>
                  <select
                    name="Packing Slip Sort"
                    id="packingSlipSort"
                    className={pageStyles.selectField}
                    value={stagingItem.packingSlipSort || ''}
                    onChange={e =>
                      updateItemField('packingSlipSort', e.target.value)
                    }
                  >
                    <option defaultValue value="">
                      Select Packing Slip Sort
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </select>
                  <label
                    className={pageStyles.formFields}
                    htmlFor="packingSlipSort"
                  >
                    Packing Slip Sort{' '}
                    <CaretDown classes={pageStyles.caretInputDropdown} />
                  </label>
                  <p className={pageStyles.publishedValue}>
                    Current value: <i>{publishedItem.packingSlipSort}</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className={pageStyles.pageBreak} />
          <div className={pageStyles.buttonContainer}>
            <div className={pageStyles.discardButtonWrapper}>
              <Button
                isTertiary
                type="button"
                handleSubmit={handleDiscard}
                key="submitButton"
                className={pageStyles.discardButton}
              >
                Discard Item Changes
              </Button>
            </div>
            <div className={pageStyles.saveAndPublishWrapper}>
              <Button isSecondary type="submit" handleSubmit={handleSubmission}>
                Save
              </Button>
              {user?.signInUserSession?.idToken?.payload[
                'cognito:groups'
              ]?.includes('menu-tool-admin') ? (
                <>
                  <Button
                    isPrimary
                    type="button"
                    handleSubmit={handlePublish}
                    key="publishButton"
                  >
                    Publish
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <MenuToolAmplifyAuthContainer />
  );
};

export default MenuItemForm;
